/// VENUES
//

import type { Canvas, Venue } from "../types";
import { del, get, put } from "./restMethods";

// Get all venues
export async function getVenues(token: string): Promise<Venue[]> {
  return get<Venue[]>("/venues", token);
}

// Get venue by slug
export async function getVenueBySlug(
  slug: string,
  token: string
): Promise<Venue> {
  return get<Venue>(`/venues/${slug}`, token);
}

// Update a venue
export async function updateVenue(venue: Venue, token: string): Promise<Venue> {
  return put<Venue>(`/venues/${venue.slug}`, venue, token);
}

// Get venue's canvases
export async function getVenueCanvases(
  venueSlug: string,
  token: string
): Promise<Canvas[]> {
  return get<Canvas[]>(`/venues/${venueSlug}/canvases`, token);
}

// Update canvas order in venue (adds canvas if needed)
export async function updateVenueCanvasOrder(
  venueSlug: string,
  canvasId: number,
  order: number,
  token: string
): Promise<void> {
  return put(
    `/venues/${venueSlug}/canvases/${canvasId}/order/${order}`,
    {},
    token
  );
}

// Remove canvas from venue
export async function removeVenueCanvas(
  venueSlug: string,
  canvasId: number,
  token: string
): Promise<boolean> {
  return del(`/venues/${venueSlug}/canvases/${canvasId}`, token);
}
